<template>
  <div class="fast_visits">
    <p class="tip">重要：急重症不适合网上咨询，请立即前往当地医院就诊！</p>
    <div class="content">
      <!-- <van-field readonly clickable name="picker" :value="storeName" label="关联药店" placeholder="请选择门店" @click="showPicker = true" />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" />
      </van-popup> -->
      <textarea class="textarea" name="" id="" cols="30" rows="10" :maxlength="500" v-model="value" :placeholder="placeholder"></textarea>
      <div class="upload">
        <!-- multiple -->
        <van-uploader :after-read="afterRead" :before-read="beforeRead" v-model="imgArr" :before-delete="onDel" use-before-read :max-count="10">
          <img src="../assets/img/upPhoto_icon.png" class="upload_img" alt="">
        </van-uploader>
        <p>1.上传相关图片（检查报告、患处照片、药品等）限十张图片</p>
        <p>2.上传检查报告、药品时，请保证关键字迹清晰可见</p>
        <p>3.上传患处照片时，请保证患处清晰可见</p>
      </div>
    </div>
    <div class="remark">
      <img src="../assets/img/safe.png" alt="">
      <p>药极客会保证您的隐私安全</p>
    </div>
    <div class="btn">
      <button @click="submit">提交问题</button>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      placeholder: '请用10-500字详细描述您的症状、疾病和身体状况，否则 可能无法获得医生的详细解答。为保障您的隐私安全，请 勿透漏您的真实姓名或联系方式。',
      value: '',
      imgArr: [],
      // showPicker: false,
      // columns: [],
      // storeName: '',
      // storeItem: {},
      // storeList: []
    }
  },
  created() {
    // this.getOpenid();
    if (!this.imgArr.length) {
      this.$store.state.arrImg = [];
    }
    // this.$store.dispatch('setPrice');
        if(this.$store.state.visitsType !== 4) {
       this.$store.dispatch('setPrice');
    }
    // this.getMerchantList();
  },
  beforeRouteEnter(to, from, next) {
    // console.log('进来之前', to, from)
    if (from.path === '/choose_patients') {
      to.meta.keepAlive = false;
    } else {
      to.meta.keepAlive = true;
    }
    next();
  },
  methods: {
    // 获取openid
    getOpenid() {
      let openId = localStorage.getItem('openid') || '';
      // console.log('openId===>', openId);
      let wxUrl = location.href;
      if (!openId) {
        // console.log('微信内置')
        // console.log(wxUrl.indexOf('code'));
        if (wxUrl.indexOf('code') > -1) {
          let code = getQueryVariable('code')
          this.$http('get', '/server/pay/getOfficialAccountOpenid?code=' + code, {}, 1).then(res => {
            // console.log(res)
            if (res.data.code == 200) {
              // openid = res.data.data;
              localStorage.setItem('openid', res.data.data)
            }
          })
        } else {
          const appid = 'wx41c11ad329b19f43'
          let http = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + wxUrl + '&response_type=code&scope=snsapi_base&state=123#wechat_redirect';
          // console.log(http)
          location.href = http;
        }
      }
    },
    beforeRead(file, cb) {
      if (file.type !== "image/jpeg" && file.type !== "image/png" && file.type !== "image/jpg") {
        Toast('"图片只能上传 PNG、JPG、JPEG 格式!"');
        return false;
      } else {
        return true;
        // cb(true);
      }
      // if (file instanceof Array) {
      //
      // } else {
      // }
    },
    afterRead(file, detail) {
      // console.log(detail);
      // console.log(Array.isArray(file));
      if (Array.isArray(file) == false) {
        file = [file]
      }
      let files = [];
      // if
      let fd = new FormData();
      file.forEach(item => {
        if (item.file.type !== "image/jpeg" &&
          item.file.type !== "image/png" &&
          item.file.type !== "image/jpg") {
          Toast('"图片只能上传 PNG、JPG、JPEG 格式!"');
          return;
        } else {
          // files.push(item.file);
          fd.append('multipartFiles', item.file);
        }
      })
      // console.log('files', files)

      fd.append('businessType', 4);

      this.$http('post', '/server/uploadImg', fd, 1).then(res => {
        // console.log('上传', res);
        if (res.data.code == 200) {
          if (this.$store.state.arrImg) {
            this.$store.state.arrImg = this.$store.state.arrImg.concat(res.data.data);
          } else {
            this.$store.state.arrImg = res.data.data;
          }
          // console.log(this.$store.state.arrImg);
          // this.imgArr.push(...res.data.data);
          // // console.log(res.data.data)
        }
      })
    },
    // 删除
    onDel(val, opt) {
      this.imgArr.splice(opt.index, 1);
      this.$store.state.arrImg.splice(opt.index, 1);
    },
    submit() {
      if (!this.imgArr.length) {
        this.$store.state.arrImg = null;
      }
      let type = this.$store.state.visitsType;
      if (this.value.length < 10) {
        Toast('描述信息字数不得小于10');
        return;
      }
      this.$store.state.message = this.value;
      if (type == 1) {
        this.$router.push('/directional_order')
      } else if (type == 2) {
        this.$router.push('/phone_order');
      }else if(type == 4) {
        this.$router.push('/fast_order')
      }else {
        if (localStorage.getItem('ykfSecretToken') && localStorage.getItem('ykfSecretToken')  != 'undefined') {
          this.createDevOrder();
        } else {
          this.$router.push('/system_doc_list');
        }
      }
    },
    createDevOrder() {
      let docDetail = this.$store.state.docDetail;
      let message = this.$store.state.message;
      let img = this.$store.state.arrImg;
      let userData = JSON.parse(localStorage.getItem('userData')) || ''
      let params = {
        memberId: userData.memberId,
        consultType: 1,
        templateType: 1,
        doctorBelong: 1, // 1药极客 2春雨
        orderPrice: this.$store.state.fastPrice.toFixed(2),
        doctorInfo: null,
        interrogationId: this.$store.state.interrogationId,
        questionContent: message,
        questionImage: img && img.join(','),
        // freeRequestUUID: localStorage.getItem('freeRequestUUID')
        orderId: localStorage.getItem('freeOrderId')
      }
      this.$http('post', '/server/order/createdOrder', params, 1).then(res => {
        if (res.data.code === 200) {
          this.$router.push('/consult');
          localStorage.removeItem('freeOrderId');
          localStorage.removeItem('ykfSecretToken');
        }
      })
      // console.log(params);
    }
    // getMerchantList() { // 获取020微商城门店列表
    //   this.$http('get', `api/open/mall/getMerchantList`, {app_id: 'wx8f6f607f03c8c6b2', lat: 0, lng: 0 }, 2).then(res => {
    //     if (res.data.level == 'success') {
    //       res.data.data.map((d) => {
    //         this.columns.push(d.name);
    //       })
    //       this.storeList = res.data.data || [];
    //     }
    //     // console.log(res);
    //   })
    // },
    // onConfirm(val, idx) {
    //   // console.log(val, idx);
    //   this.showPicker = false;
    //   this.storeName = val;
    //   this.storeItem = this.storeList[idx];
    // }
  }
}
</script>
<style lang="less" scoped>
.fast_visits {
  min-height: 100%;
  background: #f8f8f8;
  .tip {
    color: #666666;
    font-size: 0.24rem;
    text-align: center;
    line-height: 0.8rem;
  }
  .content {
    background: #fff;
    border-radius: 2px;
    padding: 0.24rem;
    margin: 0 0.24rem;
    width: calc(100% - 0.96rem);
    .textarea {
      border: 1px solid #eaeaea;
      padding: 0.3rem 0.24rem;
      width: calc(100% - 0.48rem);
    }
    .textarea::-webkit-input-placeholder {
      color: #bebebe;
      font-size: 0.24rem;
    }
    .upload {
      margin-top: 0.28rem;
      img {
        width: 80px;
        height: 80px;

        margin-bottom: 0.28rem;
      }
      p {
        font-size: 0.24rem;
        color: #8d8d8d;
      }
    }
  }
  .remark {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.24rem;
    img {
      width: 0.28rem;
      height: 0.34rem;
      margin-right: 0.16rem;
    }
    p {
      font-size: 0.24rem;
      color: #464646;
    }
  }
  .btn {
    margin: 0.6rem 0.4rem 1rem;
    width: calc(100% - 0.8rem);
    height: 0.72rem;
    line-height: 0.72rem;
    button {
      color: #fff;
      width: 100%;
      font-size: 0.32rem;
      background: #42cec8;
      border: 0;
      border-radius: 4px;
    }
  }
}
</style>
